
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import Tabs from '@/components/Tabs.vue'
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@dafcoe/vue-collapsible-panel'
import '@/assets/scss/_accordion.scss'
import TagService from '@/models/TagService'
import { ClickInfo } from '@/models/ATInternet'

const myTagService = new TagService()
export default defineComponent({
   emits: ['initFullScreen'],
  setup() {
    return {
      TabsList: [
        {
          title: 'Les étapes-clés',
          url: '/assistance-questions'
        },
        {
          title: 'le saviez-vous ?',
          url: '/assistance-questions/faqs'
        }
      ],
      Faqs: [
        {
          title: 'Qui appeler en premier&nbsp;?',
          content: '<p>Il est important de signaler sa panne sur autoroute en contactant les équipes sécurité, soit via <strong>l’application SOS Autoroute</strong>, soit via <strong>la borne orange se trouvant tous les 2 kilomètres</strong>. Le poste de sécurité du Groupe APRR contactera pour vous un dépanneur agréé qui interviendra en moyenne dans les 30 minutes.</p>' +
            '<p>Important : ne pas appeler directement votre assurance, car elle pourrait faire intervenir un dépanneur non agrée, qui ne peut pas intervenir sur autoroute. Seuls les dépanneurs agréés peuvent intervenir sur autoroute.</p>' +
            '<p>Une fois le PC prévenu, vous pouvez alors téléphoner à votre assistance, via <strong>le numéro se trouvant derrière votre carte verte</strong>, sur le pare-brise.</p>'
        },
        {
          title: 'Quel est le temps d’attente&nbsp;?',
          content: '<p>Les dépanneurs agréés pour les Véhicules Légers par le Groupe APRR sont disponibles 24h/24 toute l’année et arrivent en moyenne en 30 minutes pour vous assister.</p>'
        },
        {
          title: 'Le dépannage se déroule-t-il sur le lieu de la panne&nbsp;?',
          content: '<p>Lors de son arrivée, le dépanneur <strong>évaluera la nature de la panne</strong> et vous dira s’il peut <strong>intervenir directement ou si le véhicule doit être emmené dans un garage</strong>. En cas de possibilité d’intervention sur place, le dépanneur peut vous emmener sur une aire à proximité, afin de résoudre le problème en sécurité. Mais, dans la majorité des cas, la panne nécessite d’emmener le véhicule au garage pour avoir un diagnostic complet, pour savoir combien de temps le véhicule pourra être immobilisé au maximum et pour réparer le cas échéant.</p>' +
            '<p>Si le dépannage a lieu sur place, la facturation et le paiement s’effectueront sur place.</p>'
        },
        {
          title: 'Où vous emmène le dépanneur&nbsp;?',
          content: '<p>Si une réparation sur place est impossible, le dépanneur assure le remorquage de votre véhicule immobilisé. Votre véhicule est remorqué :</p>' +
            '<ul>' +
            '<li>Au garage du dépanneur,</li>' +
            '<li>A votre demande, à un lieu situé à moins de 5 km de la sortie de l\'autoroute ou de la voie expresse.</li>' +
            '<li>A votre demande, dans un autre lieu, en sus du forfait.</li>' +
            '</ul>'
        },
        {
          title: 'Qui gère le dossier avec l’assistance&nbsp;?',
          content: '<p>En attendant le dépanneur, vous pouvez appeler votre assisteur pour créer votre dossier : donner votre nom, le lieu de la panne, votre plaque d’immatriculation.</p>' +
            '<p>Il faudra de toutes façons rappeler votre assisteur avec le dépanneur pour donner la nature de la panne et le nom du garage (en réutilisant le même numéro de dossier). Le dépanneur vous invitera à le faire soit pendant le trajet, soit en arrivant au garage.</p>'
        },
        {
          title: 'Qui paye le dépannage&nbsp;?',
          content: '<p>Votre assisteur vous dira ce qui est pris en charge et ce qu’il reste potentiellement à votre charge. Cela dépend de votre contrat d’assurance. En cas d’absence de contrat d’assurance, la totalité sera à votre charge.</p>' +
          '<p><span class="u-t-underline">A savoir :</span> Le dépannage est souvent pris en charge par l’assurance, ce qui n’est pas le cas de la réparation. Par exemple, en cas d’essence à payer ou de pneu à changer, cela restera à votre charge.</p>'
        },
        {
          title: 'Qui paye le péage&nbsp;?',
          content: '<p>Comme pour tout trajet, vous devez payer le péage. Le dépanneur vous demandera donc votre carte bancaire et le ticket ou votre badge télépéage. Si vous ne payez pas le péage, le tarif correspondant au trajet le plus long vous sera facturé.</p>'
        },
        {
          title: 'Combien coûte un dépannage&nbsp;?',
          content: '<p>Le tarif du dépannage dépend du véhicule et de sa catégorie au péage (cf <a href="https://voyage.aprr.fr/aide-contact/mon-voyage-sur-autoroute/aires-et-services/combien-coute-un-depannage-sur-lautoroute" target="_blank" rel="noopener norefferer">voyage.aprr.fr</a>) : + de 1,8 tonnes ou moins.</p>' +
            '<p>Les prix sont réglementés par un arrêté ministériel et chaque année, ils sont revus et diffusés (site voyage.aprr.fr, affichette sur les bornes orange, dans les dépanneuses et les locaux du dépanneur etc….\n</p>'
        },
        {
          title: 'Comment se passe la suite de votre voyage&nbsp;?',
          content: '<p>Une fois au garage du dépanneur, celui-ci vous accompagnera dans les démarches administratives ainsi que pour organiser la suite des événements.</p>' +
            '<p>Le dépanneur vous proposera plusieurs solutions en fonction des propositions de votre assisteur et donc de votre contrat.</p>' +
            '<p>Les services les plus communs sont les suivants :</p>' +
            '<ul>' +
            '<li>Transport gratuit hors de l\'autoroute et jusqu\'au lieu de dépôt du véhicule en panne dans le cadre du forfait</li>' +
            '<li>Information et aide pour la mise en contact avec l\'assistance (ou assurance)</li>' +
            '<li>Aide dans la recherche d’un hôtel, d’un moyen de transport en commun ou d’un véhicule de location. En fonction de la localisation de votre panne et de votre point de départ et d’arrivée, les prises en charges ne seront pas les mêmes</li>' +
            '<li>Mise à la disposition d’un poste téléphonique, dans la limite des abonnements téléphoniques.</li>' +
            '</ul>'
        }
      ]
    }
  },
  components: {
    Header,
    Tabs,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel
  },
  methods: {
    openQuestion(title) {
      const actionName = 'FAQ : ' + title
       myTagService.click({ name: actionName, type: 'navigation' } as ClickInfo)
    }
  }
})
